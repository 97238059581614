.ui-view-container {
  position: relative;
  overflow: hidden;
}

[ui-view].ng-enter, [ui-view].ng-leave {
  position: absolute;
  left: 15px;
  right: 15px;
  transition:all .25s ease-in-out;
}

[ui-view].ng-enter {
  opacity: 0;
  transform:translate3d(-100px, 0, 0);
}

[ui-view].ng-enter-active {
  opacity: 1;
  transform:translate3d(0, 0, 0);
}

[ui-view].ng-leave {
  opacity: 1;
  transform:translate3d(0, 0, 0);
}

[ui-view].ng-leave-active {
  opacity: 0;
  transform:translate3d(100px, 0, 0);
}