@import "bootstrap-variable-overrides";
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "variables";
@import "mixins/respond";
@import "spacing";
@import "typography";
@import "utilities/text-align";

@import "components/sidenav";
@import "components/bottom-nav";
@import "components/issue-cycle";
@import "components/step-number";
@import "components/main-column";
@import "components/tip-box";
@import "components/tip-box-animation";
@import "components/_ui-view-animation";

body{
  overflow-y: scroll;
}

