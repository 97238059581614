
@mixin respond($media) {
    @if $media == tiny {
        @media (max-width: $tiny-max) { @content; }
    }
    @else if $media == xs {
        @media (max-width: $screen-xs-max) { @content; }
    }
    @else if $media == xs-only {
        @media (min-width: $tiny-max + 1px) and (max-width: $screen-xs-max) { @content; }
    }
    @else if $media == sm {
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { @content; }
    }
    @else if $media == sm-up {
        @media (min-width: $screen-sm-min) { @content; }
    }
    @else if $media == sm-down {
        @media (max-width: $screen-sm-max) { @content; }
    }
    @else if $media == md {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) { @content; }
    }
    @else if $media == md-up {
        @media (min-width: $screen-md-min) { @content; }
    }
    @else if $media == md-down {
        @media (max-width: $screen-md-max) { @content; }
    }
    @else if $media == sm-and-md {
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { @content; }
    }
    @else if $media == lg {
        @media (min-width: $screen-lg-min) { @content; }
    }
    @else if $media == mobile-menu {
        @media (max-width: $screen-sm-max) { @content; }
    }
}