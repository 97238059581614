html,
body {
  font-size: $font-size-base;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.025em;
}


// Links
//
// Markup:
// <a class="{{modifier_class}}" href="javascript:void(0)">link</a>
//
// Styleguide Typography.2-links.

// Headers
//
// Markup:
// <h1>An h1 header</h3>
// <h2>An h2 header</h3>
// <h3>An h3 header</h3>
// <h4>An h4 header</h3>
//
// Styleguide Typography.3-headers.
h4,
h3 {
  font-weight: 400;
  margin-bottom: 30px;

  @include respond(xs) {
    font-size: 28px;
  }
}

h4 {
  margin-bottom: 20px;

  @include respond(xs) {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 10px;
  }

  &.header-gray-sm-up {
    @include respond(sm) {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

a{
  text-decoration: underline;
  padding-left: 4px;
}

.text-md,
.text-sm {
  font-size: 22px;
  font-weight: 400;

  @include respond(xs) {
    font-size: 18px;
  }
}

// Text
//
// Markup:
// <span class="{{modifier_class}}">An span text</span>
//
// .text-sm   - Small text, even smaller on xs
// .text-sm-up   - Just smaller on sm
//
// Styleguide Typography.1-text.
.text-sm {
  font-size: 16px;
  line-height: 1.7;

  @include respond(xs) {
    font-size: 14px;
  }
}

.text-sm-up {
  @include respond(sm) {
    font-size: 14px;
  }
}