///////////////////////////////////
// MAIN SIDEBAR
///////////////////////////////////

.main-sidebar {
  position: fixed;
  left: 0;
  z-index: 1;
  background: white;
  height: 100%;

  @include respond(sm-down) {
    opacity: 0;
    transform: translateX(-100%);
    transition-property: transform, opacity, box-shadow;
    transition-duration: .2s, .2s, 1s;
    box-shadow: none;
    width: 300px;

    &.open {
      transform: none !important;
      opacity: 1 !important;
      box-shadow: 0 0 150px 100vw  rgba(0, 0, 0, .75);
    }
  }

  .main-logo {
    background-image: url("../img/codewave-full.svg");
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 70px;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 2px solid $cw-gray;
  }


  .main-sidebar__footer {
    border-top: 2px solid $cw-gray;

    position: absolute;
    bottom: 0;
    padding: 29px 20px;
    left: 0;
    right: 0;
  }
}

// Main sidebar nav
//
// Markup:
// <ul class="main-sidebar__navigation" ng-click="sideNav.open = !sideNav.open">
//     <li class="first-level-list"><a href="#" class="active-state">Active first level</a></li>
//     <li class="first-level-list">
//         <a href="#">First level</a>
//         <ul>
//             <li>
//                 <a class="second-level-dropdown-list" href="#">Second level</a>
//                 <ul>
//                     <li><a href="#">Third level</a></li>
//                     <li><a href="#" class="active-state">Active third level</a></li>
//                 </ul>
//             </li>
//             <li><a class="second-level-dropdown-list active-state" href="#">Active second level</a></li>
//         </ul>
//     </li>
// </ul>
//
// Styleguide: Components.2-sidenav

.main-sidebar__navigation {
  list-style-type: none;
  padding-left: 25px;
  padding-bottom: 20px;
  height: calc(100vh - 143px);
  overflow-y: auto;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 0;

  ul {
    list-style-type: none;
    padding-left: 15px;
  }

  .first-level-list {
    font-weight: 700;
    color: $cw-dark-blue;

    ul {
      color: $cw-dark-gray;
      font-weight: 600;

      li {
        .second-level-dropdown-list {
          &:after {
            content: '\f0d7';
            font-family: FontAwesome;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            text-decoration: none;
            color: $cw-dark-gray;
            margin-left: 5px;
          }

          &:hover,
          &:focus,
          &:visited {
            text-decoration: none;
          }

          &.active-state {
            &:after {
              content: '\f0d8';
            }
          }
        }

        ul {
          font-weight: 400;
        }
      }
    }
  }

  a{
    position: relative;
    text-decoration: none;
    display: inline-block;
    padding: 10px 10px 10px 0;

    &:before {
      content: '';
      font-size: 1.1em;
      background-image: url("../img/codewave-badge.svg");
      background-size: cover;
      display: inline-block;
      position: absolute;
      right: calc(100% + 5px);
      width: 1em;
      height: 1em;
      top: calc(50% - .5em);
      filter: blur(2.6px);
      transform: translateX(40px) scaleX(10) scaleY(2);
      opacity: .4;
      transition: transform .15s, filter .15s, opacity .15s;
      pointer-events: none;
    }

    &.active-state, &:hover {


      &:before {
        filter: blur(.7px);
        transform: translateX(0);
        opacity: 1;

      }
    }
  }
}


.sidenav-toggle {
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 2;
  background: $cw-blue;
  border: none;
  padding: 5px 10px;

  .fa {
    color: $cw-white;
    font-size: 26px;
  }

  &:focus,
  &:active {
    outline-color: transparent;
  }

  &.active {
    background-color: transparent;

    .fa {
      color: $cw-dark-gray;
    }
  }
}