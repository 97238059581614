
///////////////////////////////////
// BOTTOM NAVIGATION
///////////////////////////////////
.bottom-nav-wrapper{
  position: fixed;
  bottom: 0
}
.row-bottom-fixed {
  background-color: $cw-gray;
  height: 80px; //temporary

  @include respond(xs) {
    height: 60px;
  }

  .col-bottom-navigation__left,
  .col-bottom-navigation__right {
    > a {
      position: absolute;
      text-align: center;
      left: 5px;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      font-weight: bold;
      transition: opacity .2s;

      @include respond(xs) {
        font-size: 14px;
      }

      &:hover {
        text-decoration: none;
        opacity: .7;
      }

      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .col-bottom-navigation__left {
    height: 80px;

    @include respond(xs) {
      height: 60px;
    }

    > a {
      color: $cw-middle-gray-dark;
      text-decoration: none;
      &:before {
        content: '\f060';
        font-family: FontAwesome;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        text-decoration: none;
        color: $cw-middle-gray-dark;
        left: 0;
        margin-right: 10px;

        @include respond(xs) {
          font-size: 14px;
        }
      }
    }
  }

  .col-bottom-navigation__right {
    background-color: $cw-blue;
    height: 80px;

    @include respond(xs) {
      height: 60px;
    }

    > a {
      color: $cw-white;
      text-decoration: none;

      &:after {
        content: '\f061';
        font-family: FontAwesome;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        text-decoration: none;
        color: $cw-white;
        left: 0;
        margin-left: 10px;

        @include respond(xs) {
          font-size: 14px;
        }
      }
    }
  }
}