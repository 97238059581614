///////////////////////////////////
// ISSUE CYCLE
///////////////////////////////////

.cycle-item,
.cycle-item-dark,
.cycle-item-dark-side {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  background-color: $cw-blue;
  margin: 0 auto;

  @include respond(xs) {
    height: 100px;
    width: 100px;
  }

  @include respond(sm) {
    height: 100px;
    width: 100px;
  }

  @include respond(lg) {
    width: 150px;
    height: 150px;
  }

  .cycle-item__popover {
    float: right;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: $cw-middle-gray-dark;
    z-index: 0;
    cursor: pointer;

    .fa {
      font-size: 20px;
      color: $cw-white;
      margin-top: 7px;
    }

    &:focus {
      outline: none;
    }
  }

  .popover {
    width: 300px;
    border-radius: 0;
    color: $cw-dark-gray;

    .popover-title {
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 0;
      padding-top: 12px;
    }

    .popover-content {
      font-family: 'Open Sans', sans-serif;
      padding-bottom: 12px;
    }
  }

  &:after {
    content: '\f063';
    font-family: FontAwesome;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #cacaca;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;

    @include respond(xs) {
      font-size: 26px;
    }
  }

  &.no-arrow {
    &:after {
      display: none;
    }
  }

  &.before-arrow {
    &:before {
      content: '\f063';
      font-family: FontAwesome;
      font-size: 30px;
      font-weight: normal;
      font-style: normal;
      text-decoration: none;
      color: #cacaca;
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;

      @include respond(xs) {
        font-size: 26px;
      }
    }
  }

  .text-sm {
    color: white;
    font-size: 18px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    @include respond(md) {
      font-size: 15px;
    }

    @include respond(sm) {
      font-size: 12px;
    }

    @include respond(xs) {
      font-size: 10px;
    }
  }
}

.cycle-item-dark,
.cycle-item-dark-side{
  background-color: $cw-dark-blue;
}

.cycle-item-dark-side {
  &:after {
    content: '\f061';
    font-family: FontAwesome;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #cacaca;
    position: absolute;
    bottom: auto;
    left: auto;
    right: -13px;
    top: 80px;

    @include respond(xs) {
      font-size: 26px;
      top: 15px;
    }

    @include respond(sm) {
      right: auto;
      left: 90%;
      top: 50px;
    }

    @include respond(md) {
      right: auto;
      left: 89%;
      top: 65px;
    }

    @include respond(lg) {
      right: auto;
      left: 97%;
      top: 80px;
    }
  }

  &:before {
    content: '\f060';
    font-family: FontAwesome;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #cacaca;
    position: absolute;
    bottom: auto;
    left: auto;
    right: -13px;
    top: 32px;

    @include respond(xs) {
      font-size: 26px;
      top: 50px;
    }

    @include respond(sm) {
      right: auto;
      left: 90%;
      top: 10px;
    }

    @include respond(md) {
      right: auto;
      left: 89%;
      top: 25px;
    }

    @include respond(lg) {
      right: auto;
      left: 97%;
      top: 30px;
    }
  }
}

.cycle-item-dark-with-line {
  height: 125px;
  width: 125px;
  border-radius: 50%;
  background-color: $cw-dark-blue;
  margin: 0 auto;

  @include respond(xs) {
    height: 100px;
    width: 100px;
  }

  @include respond(sm) {
    height: 100px;
    width: 100px;
  }

  @include respond(lg) {
    width: 150px;
    height: 150px;
  }

  &:after {
    content: '\f2d1';
    font-family: FontAwesome;
    font-size: 23px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #cacaca;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    margin-left: 12px;

    @include respond(xs) {
      font-size: 21px;
    }
  }

  .text-sm {
    color: white;
    font-size: 18px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    @include respond(md) {
      font-size: 15px;
    }

    @include respond(sm) {
      font-size: 12px;
    }

    @include respond(xs) {
      font-size: 10px;
    }
  }
}

.side-text {
  position: absolute;
}