
/*
  Tip Box

  Markup:
  <div class="tip-box-counter-reset">
      <div class="tip-box">
          <div class="tip-box__content">
              <h4>Make sure all current designs are accessible from the same links.</h4>
          </div>
          <div class="tip-box__content">
              <p>
                  If a wireframe updates, it should be accessible from the same link as previous version - this way
                  there is
                  no possibility that we'll start working on old designs.
              </p>
          </div>
      </div>
      <div class="tip-box">
          <div class="tip-box__content">
              <h4>Make sure all current designs are accessible from the same links.</h4>
          </div>
          <div class="tip-box__content">
              <p>
                  If a wireframe updates, it should be accessible from the same link as previous version - this way
                  there is
                  no possibility that we'll start working on old designs.
              </p>
          </div>
      </div>
  </div>

  Styleguide: Components.1-tool-box
*/

.tip-box-counter-reset{
  counter-reset: cnt;
}

.tip-box{

  display: flex;
  align-items: stretch;
  margin-top: -1px;

  @include respond(sm-down){
    flex-direction: column !important;
    margin-top: 15px;
    .tip-box__content{
      border-radius: 2em !important;
      padding: 20px 15px;
    }
  }

  &:nth-child(even){
    flex-direction: row-reverse;

    > :nth-child(even){
      border: 1px solid $cw-gray;
      border-radius: 0;
      border-top-left-radius: 2em;
      border-bottom-left-radius: 2em;
    }
  }

  &:first-child{
    > :nth-child(even){
      border: 1px solid $cw-gray;
      border-top-left-radius: 2em;
    }
  }

  &:last-child{
    > :nth-child(even){
      border: 1px solid $cw-gray;
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
    }
  }

  &__content{
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    padding: 50px 25px;
    position: relative;
    &:nth-child(even){
      border: 1px solid $cw-gray;
      background-color: white;
      border-radius: 2em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    h1,h2,h3,h4{
      color: $cw-dark-blue;
      margin: 0;

      &::before{
        counter-increment: cnt;
        position: absolute;
        font-size: 8rem;
        content: counter(cnt);
        right: 15px;
        bottom: 10px;
        font-weight: bold;
        opacity: .08;
      }
    }
    p{
      margin: 0;
    }
  }
}