///////////////////////////////////
// MAIN COLUMN
///////////////////////////////////

.col-main {
    padding-bottom: 120px;
    border-left: 2px solid $cw-gray;
    background-color: $cw-light-gray;
    min-height: calc(100vh - 80px);
    transition: width .2s;

    @include respond(xs) {
        min-height: calc(100vh - 60px);
    }

    .row-main-header {
        background-color: $cw-blue;

        .main-header {
            color: $cw-white;
            font-size: 58px;
            font-weight: 400;
            line-height: 1;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 80px 0;

            @include respond(xs) {
                font-size: 40px;
            }
        }
    }

    .col-main__content {
        padding-top: 40px;
    }
}