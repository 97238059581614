@mixin spacing($screen-name,$index) {
  .mt#{$screen-name}-#{$index} {
    margin-top: $grid-gutter-width/2 * $index !important;
  }
  .mb#{$screen-name}-#{$index} {
    margin-bottom: $grid-gutter-width/2 * $index !important;
  }
  .ml#{$screen-name}-#{$index} {
    margin-left: $grid-gutter-width/2 * $index !important;
  }
  .mr#{$screen-name}-#{$index} {
    margin-right: $grid-gutter-width/2 * $index !important;
  }
  .mx#{$screen-name}-#{$index} {
    @extend .ml#{$screen-name}-#{$index};
    @extend .mr#{$screen-name}-#{$index};
  }
  .my#{$screen-name}-#{$index} {
    @extend .mt#{$screen-name}-#{$index};
    @extend .mb#{$screen-name}-#{$index};
  }

  .m#{$screen-name}-#{$index} {
    @extend .mx#{$screen-name}-#{$index};
    @extend .my#{$screen-name}-#{$index};
  }

  .pt#{$screen-name}-#{$index} {
    padding-top: $grid-gutter-width/2 * $index !important;
  }
  .pb#{$screen-name}-#{$index} {
    padding-bottom: $grid-gutter-width/2 * $index !important;
  }
  .pl#{$screen-name}-#{$index} {
    padding-left: $grid-gutter-width/2 * $index !important;
  }
  .pr#{$screen-name}-#{$index} {
    padding-right: $grid-gutter-width/2 * $index !important;
  }
  .px#{$screen-name}-#{$index} {
    @extend .pl#{$screen-name}-#{$index};
    @extend .pr#{$screen-name}-#{$index};
  }
  .py#{$screen-name}-#{$index} {
    @extend .pt#{$screen-name}-#{$index};
    @extend .pb#{$screen-name}-#{$index};
  }

  .p#{$screen-name}-#{$index} {
    @extend .px#{$screen-name}-#{$index};
    @extend .py#{$screen-name}-#{$index};
  }

}

//-----------------------------GLOBAL SPACINGS---------------------------------------
@for $i from 0 to 10 {
  @include spacing('', $i);
}

//-----------------------------TABLET LANDSCAPE BREAKPOINT DEFINITION-----------------------------
$screen-sm-min-tablet-landscape: $screen-sm-min;
$screen-sm-max-tablet-landscape: 1024px;

$screen-md-min-but-no-tablet-landscape: 1025px;
$screen-md-max-but-no-tablet-landscape: $screen-md-max;

//-----------------------------SPACINGS FOR SPECIFIED SCREEN-----------------------------
$screen-collection: ('-xs', '-sm', '-sm-tablet-landscape', '-md', '-md-but-no-tablet-landscape', '-lg');
$screen-sizes-min: ('------------', $screen-sm-min, $screen-sm-min-tablet-landscape, $screen-md-min, $screen-md-min-but-no-tablet-landscape, $screen-lg-min);
$screen-sizes-max: ($screen-xs-max, $screen-sm-max, $screen-sm-max-tablet-landscape, $screen-md-max, $screen-md-max-but-no-tablet-landscape, '------------');

@for $j from 0 to length($screen-collection) {
  @for $i from 0 to 10 {
    @if ($j == 0) {
      @media (max-width: #{nth($screen-sizes-max, $j+1)}) {
        @include spacing(nth($screen-collection, $j+1), $i);
      }
    } @else if ($j == length($screen-collection)-1) {
      @media (min-width: #{nth($screen-sizes-min, $j+1)}) {
        @include spacing(nth($screen-collection, $j+1), $i);
      }
    } @else {
      @media (min-width: #{nth($screen-sizes-min, $j+1)}) and (max-width: #{nth($screen-sizes-max, $j+1)}) {
        @include spacing(nth($screen-collection, $j+1), $i);
      }
    }
  }
}

.hidden-sm-tablet-landscape {
  @media (min-width: $screen-sm-min-tablet-landscape) and (max-width: $screen-sm-max-tablet-landscape) {
    display: none !important;
  }
}

.hidden-md-but-no-tablet-landscape {
  @media (min-width: $screen-md-min-but-no-tablet-landscape) and (max-width: $screen-md-max-but-no-tablet-landscape) {
    display: none !important;
  }
}

.visible-sm-tablet-landscape {
  display: none !important;
  @media (min-width: $screen-sm-min-tablet-landscape) and (max-width: $screen-sm-max-tablet-landscape) {
    display: block !important;
  }
}

.visible-sm-tablet-landscape-inline-block {
  display: none !important;
  @media (min-width: $screen-sm-min-tablet-landscape) and (max-width: $screen-sm-max-tablet-landscape) {
    display: inline-block !important;
  }
}

.inline-block {
  display: inline-block !important;
}

.pull-sm-right {
  @media (min-width: $screen-sm-min) {
    float: right !important;
  }
}