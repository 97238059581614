.tip-box{
  opacity: 0;
  @keyframes slideinfromleft {
    from{
      opacity: 0;
      transform: translateX(-100px);
    }
    to{
      opacity: 1;
      transform: translateX(0);
    }
  }
  animation-name: slideinfromleft;
  animation-duration: .25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @for $i from 0 through 100 {
    &:nth-child(#{$i}){
      animation-delay: .1s * $i;
    }
  }
}